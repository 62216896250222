//ToDo Timer fixen
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameServiceService } from '../game-service.service';
import { WebSocketService } from '../web-socket.service';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from 'src/environments/environment';
import cards from '../card/cards.json';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css']
})
export class GameComponent implements OnInit {
  public gameID;
  public playerName: string = "";
  public joined: boolean = false;
  public playerList: string[] = []
  public myCards: number[] = [];
  public currentPlayer: number;
  public myPlayer: number;
  public gameRunning: boolean;
  public currentTurn: { player: string, cardID: number }[] = [];
  public eval: any = null;
  public vorbehaltPhase: boolean = false;
  public loginError: string;
  public moveError: string;
  private socket: WebSocketSubject<unknown>;
  public msg: any;
  public MessageTypeEnum = MessageType;
  public msgType: MessageType = MessageType.NONE;
  private _inverted: boolean;
  public schweinchen: boolean = false;
  public untilNewGame: number = -1;
  public timer: any;
  public remember: boolean = false;
  public winners: string[] = [];
  public set inverted(value: boolean) {
    this._inverted = value;
    this.sortCards();
  }
  public get inverted(): boolean {
    return this._inverted;
  }
  public playedVorbehalt: boolean = false;
  public gameType: string
  public publicCards?: {offen: number[],verdeckt: number,name: string, hand: number}[]
  public stackTop: number
  public stackSize: number

  constructor(private route: ActivatedRoute, private gameService: GameServiceService, private ws: WebSocketService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.gameID = params.get('gameID');
    });
    this.connectSocket();
    console.log("trying to resume")
    this.socket.next({ type: "resume" });
  }
  public onPlay(card: number): void {
    if ((card === 28 && this.myCards.includes(29) || card === 29 && this.myCards.includes(28)) && this.gameType !== "shithead") {
      this.schweinchen = true;
      return;
    }
    console.log("sending card:" + card);
    this.socket.next({ type: "play", card: card });
  }
  public join(): void {
    this.gameService.join(this.gameID, this.playerName, this.remember).subscribe((data: {players:[string], gameType: string}) => {
      this.playerList = data.players;
      this.myPlayer = data.players.length - 1;
      this.gameType = data.gameType;
      this.connectSocket();
      this.socket.next({ type: "ready" });
      this.joined = true;
    },
      (error) => {
        if (!(error.error instanceof ErrorEvent)) {
          this.loginError = error.error.error;
        } else {
          this.loginError = "Lost connection";
        }

      })
  }
  public myTurn(): boolean {
    return this.myPlayer === this.currentPlayer;
  }

  private onMessage(msg: any): void {
    console.log(msg);
    switch (msg.type) {
      case "joined":
        this.playerList.push(msg.name);
        break;
      case "cards":
        this.myCards = msg.cards;
        this.sortCards();
        console.log(this.myPlayer);
        console.log(this.playerList);
        this.gameRunning = true;
        break;
      case "curPlayer":
        this.currentPlayer = msg.currentPlayer;
        console.log(this.currentPlayer);
        this.vorbehaltPhase = (msg.vorbehalt !== undefined);
        break;
      case "played":
        if (this.currentTurn.length === 4) {
          this.currentTurn.splice(0, 4);
        }
        this.currentTurn.push({ player: msg.player, cardID: msg.card });
        break;
      case "eval":
        this.eval = msg.result;
        break;
      case "error":
        this.msg = msg.msg;
        this.msgType = MessageType.ERROR;
        setTimeout(() => { this.msgType = MessageType.NONE }, 3000);
        this.playedVorbehalt = false;
        break;
      case "gameState":
        this.msgType = MessageType.NONE;
        this.currentTurn = msg.turn;
        this.myCards = msg.cards;
        this.playerName = msg.name;
        this.playerList = msg.players;
        this.gameRunning = true;
        this.vorbehaltPhase = msg.vorbehalt;
        this.currentPlayer = msg.currentPlayer;
        this.myPlayer = this.playerList.findIndex((name: string) => { return name === this.playerName });
        this.joined = true;
        this.sortCards();
        break;
      case "playedVorbehalt":
        switch (msg.vorbehalt) {
          case "hochzeit":
            this.msgType = MessageType.HOCHZEIT;
            this.msg = this.playerList[msg.player]
            setTimeout(() => { this.msgType = MessageType.NONE }, 3000);
            break;
          case "rückgabe":
            this.msgType = MessageType.RUCKGABE;
            this.msg = this.playerList[msg.player]
            setTimeout(() => { this.msgType = MessageType.NONE }, 3000);
            break;
        }
        break;
      case "newGame":
        this.untilNewGame = 5;
        let timerID = setInterval(() => {
          this.untilNewGame -= 1;
          if (this.untilNewGame <= 0) {
            this.untilNewGame = -1;
            this.eval = null;
            this.currentTurn = [];
            clearInterval(timerID);
          }
        }, 1000);
        break;
      case "publicCards":
        this.publicCards = msg.publicCards
        break;
      case "topStack":
        this.stackTop = msg.card 
        this.stackSize =msg.stackSize
        break;
      case "winner":
        this.winners = msg.winners
        break;
      case "resumeShithead":
        this.playerName = msg.name
        this.playerList = msg.players
        this.gameType = "shithead"
        this.joined = true
        this.myPlayer = this.playerList.findIndex((name: string) => { return name === this.playerName });
        break;
    }
  }
  public onError(err) {
    if (this.playerName !== "") {
      this.msgType = MessageType.CONNECTION_LOST;
    }

  }
  public reconnect() {
    this.msgType = MessageType.RECONNECTING;
    this.connectSocket();
    this.socket.next({ type: "resume" })
  }
  public hochzeit(): boolean {
    return this.myCards.includes(44) && this.myCards.includes(45);
  }
  public ruckgabe(): boolean {
    let neunen = 0;
    for (const card of [0, 1, 6, 7, 14, 15, 22, 23]) {
      if (this.myCards.includes(card)) {
        neunen += 1;
      }
    }
    return neunen > 4;
  }
  public vorbehalt(vorbehalt: string): void {
    this.socket.next({ type: "vorbehalt", vorbehalt: vorbehalt });
    this.playedVorbehalt = vorbehalt !== "rückgabe";
  }
  public newGame(): void {
    this.socket.next({ type: "newGame" });
  }

  public sortCards() {
    const sorter = this.inverted ? function (a: number, b: number) { return b - a } : function (a: number, b: number) { return a - b }
    this.myCards.sort(sorter);
  }
  public playSchweinchen(trumpf: number) {
    this.socket.next({ type: "schweinchen", trumpf: trumpf });
    this.schweinchen = false;
  }
  public connectSocket(): void {
    const url = window.location;
    this.socket = webSocket((url.protocol == "https:" ? "wss://" : "ws://") + url.hostname + ":" + url.port);
    this.socket.subscribe(
      (msg) => this.onMessage(msg),
      (err) => this.onError(err)
    )
  }
  public takeStack(): void{
    this.socket.next({ type: "takeStack"});
  }
  public playVerdeckt(): void{
    this.socket.next({type: "playVerdeckt"})
  }
}
export enum MessageType {
  NONE,
  ERROR,
  CONNECTION_LOST,
  RECONNECTING,
  HOCHZEIT,
  SCHWEINCHEN,
  KONTRA,
  RE,
  RUCKGABE
}
