import { Injectable } from '@angular/core';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  constructor() {}

  private socket: WebSocketSubject<unknown>;

  public create(onMessage?: (msg: any) => void): void{
    this.socket =  webSocket('ws://localhost:8080');
    this.socket.subscribe(
      (msg) => onMessage(msg)
    )
    this.socket.next({type: "ready"});
  }
}
