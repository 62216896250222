<div class="modal" *ngIf="eval != null">
    <div class="scoreboard" >
        <div class="scoretable" *ngFor="let team of eval"> 
            <h2> {{ names(team.players)}}</h2>
            <h3>Gesamtaugenzahl: {{team.points }}</h3>
            <table>
                <tr><th>Spieldetail</th><th>Punkte</th></tr>
                <tr *ngFor="let detail of team.detail">
                    <td *ngIf="detail.detail == 'win'">Gewonnen</td>
                    <td *ngIf="detail.detail  == 'against'">gegen die Alten</td>
                    <td *ngIf="detail.detail  == 'u90'">keine 90</td>
                    <td *ngIf="detail.detail  == 'u60'">keine 60</td>
                    <td *ngIf="detail.detail  == 'u30'">keine 30</td>
                    <td *ngIf="detail.detail  == 'schwarz'">schwarz</td>
                    <td *ngIf="detail.detail  == 'doppelkopf'">Doppelkopf</td>
                    <td *ngIf="detail.detail  == 'karlchen'">Karlchen Müller</td>
                    <td *ngIf="detail.detail  == 'fuchs'">Fuchs gefangen</td>
                    <td>{{ detail.points }} </td>
                </tr>
            </table>
        </div>
        <button *ngIf="untilNewGame === -1; else untilBlock" (click)="onNewGame()">Neues Spiel</button>
        <ng-template #untilBlock>
            Neues Spiel in {{untilNewGame}} Sekunden.
        </ng-template>
    </div>
</div>

