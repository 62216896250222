import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { GameComponent } from './game/game.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';


const routes: Routes = [
  {path: '', component: IndexComponent},
  {path: 'game/:gameID', component: GameComponent},
  {path: 'datenschutz', component: DatenschutzComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
