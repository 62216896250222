import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GameServiceService {

  constructor(private http: HttpClient) { }

  public join(gameID : string, playerName: string, remember: boolean){
    return this.http.post("join",{gameID: gameID,name: playerName, remember: remember});
  }
}
