import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-eval',
  templateUrl: './eval.component.html',
  styleUrls: ['./eval.component.css']
})
export class EvalComponent implements OnInit {
  @Input() eval:any;
  @Input() player: String[];
  @Output() newGame= new EventEmitter();
  @Input() untilNewGame: number;
  constructor() { }

  ngOnInit(): void {
  }
  public names(indeces: number[]) {
    var result = "";
    for(const index of indeces){
      result += " " + this.player[index];
    }
    return result;
  }
  onNewGame(){
    this.newGame.emit();
  }
}
