import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import cardsDoppelkopf from './cards.json';
import cardsShithead from './cards_shithead.json'

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  @Input() public cardID: number | undefined;
  public _gameType: string
  @Input() public set gameType( value: string){
    this._gameType = value
    if (this._gameType === "shithead") {
      this.cards = cardsShithead
    }
  }
  @Output() played = new EventEmitter<number>();
  cards: any = cardsDoppelkopf
  constructor() { }

  ngOnInit(): void {
    console.log(this.cardID);
    if (this._gameType === "shithead") {
      this.cards = cardsShithead
    }
  }
  cardString(): string {

    if (this.cardID === undefined) {
      return "";
    } else {
      const card = this.cards[this.cardID];
      //console.log(card);
      switch (card.color) {
        case "Pik":
          return "♠ " + card.type;
        case "Herz":
          return "♥ " + card.type;
        case "Kreuz":
          return "♣ " + card.type;
        case "Karo":
          return "♦ " + card.type;
        default:
          return card.type
      }
    }
  }
  onClick(): void {
    this.played.emit(this.cardID);
    console.log("Emitting:" + this.cardID);
  }
  cardColor(): string {
    if (this.cardID === undefined) {
      return "black";
    } else {
      const card = this.cards[this.cardID];
      switch (card.color) {
        case "Pik":
          return "black";
        case "Herz":
          return "red";
        case "Kreuz":
          return "black";
        case "Karo":
          return "red";
      }
    }
  }
}
