<main>
<h2>Datenschutzerklärung</h2>
<p>Verantwortlicher für die Datenverarbeitung im Sinne des Art. 13 DSGVO ist</p>
<address>

	<dl>
		<dt>Elektronische Post (E-Mail)</dt>
		<dd>&#9993; <a href="mailto:timmar2000@timmar2000.de">timmar2000@timmar2000.de</a></dd>
	</dl>
</address>
<p>Der Verantwortliche entscheidet alleine über die Zwecke und Mittel der Datenverarbeitung.</p>

<h3>Zwecke und Rechtsgrundlage der Datenverarbeitung</h3>
<ul>
	<li>
		<p>Der Webserver speichert in seinen Log-Dateien Informationen, die durch Ihren Webbrowser automatisch
		übermittelt werden, im Speziellen
		<ul>
			<li>die Browseridentifikation (Browsertyp, -version und verwendetes Betriebssystem),</li>
			<li>den Referrer (die Seite, welche auf die besuchte Webseite verwiesen hat),</li>
			<li>die öffentliche IP-Adresse des zugreifenden Rechners sowie</li>
			<li>Datum und Uhrzeit der Anfrage.</li>
        </ul>
		<p>Die in diesem Zusammenhang erhobenen Daten werden nicht mit anderen Daten zusammengeführt.</p>
		<p>Diese Verarbeitung ist zur Wahrung der berechtigten Interessen des Websitebetreibers erforderlich
		und damit nach Art. 6 Abs. 1 lit. f DSGVO rechtmäßig.</p>
	</li>
	<li>
		<p>Es werden Seiteneinstellungen über Storage-Objekte auf Ihrem Rechner gespeichert.</p>
		<p>Die in diesem Zusammenhang erhobenen Daten werden nicht mit anderen Daten zusammengeführt.</p>
		<p>Diese Verarbeitung ist zur Nutzung der Webseite erforderlich
		und damit nach Art. 6 Abs. 1 lit. b DSGVO rechtmäßig.</p>
	</li>
</ul>

<h3>Kategorien der verarbeiteten personenbezogenen Daten</h3>
<p>Diese Website verarbeitet keine personenbezogenen Daten besonderer Kategorien im Sinne des Art. 9 DSGVO.

<h3>Empfänger der personenbezogenen Daten</h3>
<p>Die personenbezogenen Daten werden nicht an Dritte weitergegeben.</p>

<h3>Dauer der Speicherung der personenbezogenen Daten</h3>
<p>Alle personenbezogenen Daten werden nicht länger als fünfzehn Tage gespeichert.</p>

<h3>Recht auf Datenübertragbarkeit</h3>
<p>Sie haben das Recht, sich Ihre Daten, welche wir automatisiert verarbeiten, an sich oder an Dritte übertragen zu
lassen.  Die Bereitstellung erfolgt in einem maschinenlesbaren Format.  Die Übertragung an Dritte erfolgt, soweit dies
technisch möglich ist.</p>

<h3>Recht auf Auskunft, Berichtigung, Sperrung und Löschung</h3>
<p>Sie haben das Recht, unentgeltlich Auskunft über Ihre gespeicherten Daten einschließlich Herkunft und Zweck zu
erhalten sowie ggf. das Recht auf Berichtigung, Sperrung oder Löschung der Daten.</p>

<h3>Recht auf Beschwerde</h3>
<p>Sie haben das Recht, sich über einen datenschutzrechtlichen Verstoß bei der zuständigen Aufsichtsbehörde zu
beschweren.  Zuständig ist der Landesdatenschutzbeauftragte des Bundeslandes Schleswig-Holstein, in welchem sich
unser Sitz befindet.  Der Bund stellt <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank" rel="noopener noreferrer">eine Liste der Datenschutzbeauftragten einschließlich Ihrer Kontaktdaten</a> bereit.</p>

<h3>Recht auf Widerspruch</h3>
<p>Sie haben das Recht, jederzeit gegen die Verarbeitung Ihrer Daten Widerspruch einzulegen.  Die Verarbeitung der
Daten wird dann eingestellt, solange wir nicht zwingende schutzwürdige Gründe für die Verarbeitung nachweisen können,
welche Ihre Interessen, Rechte und Freiheiten überwiegen oder die Verarbeitung der Geltendmachung, Ausübung oder
Verteidigung von Rechtsansprüchen dient.</p>

<h3>Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling</h3>
<p>Es besteht keine automatisierte Entscheidungsfindung einschließlich Profiling.</p>
</main>
