<div class="status">
    <div *ngIf="gameRunning; else waitBlock">
        <div *ngIf="!myTurn(); else elseBlock">
            {{ playerList[currentPlayer] }} ist an der Reihe
        </div>
        <ng-template #elseBlock class="yourTurn">Du bist an der Reihe</ng-template>
    </div>
    <ng-template #waitBlock>Warten auf Spieler</ng-template>
</div>
<ul class="playerList">
    <li class="player" *ngFor="let name of playerList"> {{ name }}</li>
</ul>
<div *ngIf="stackTop != undefined; else turn">
    <app-card [cardID]="stackTop" [gameType]="gameType" (click)="takeStack()"></app-card>
    <p>{{ stackSize }} Karten</p>
</div>
<ng-template class=turn #turn>
    <div class="turnCard" *ngFor="let move of currentTurn">
        <p>{{ move.player }}</p>
        <app-card [cardID]="move.cardID" ></app-card>
    </div>
</ng-template>
<div class="msg" *ngIf="msgType !== MessageTypeEnum.NONE">
    <div *ngIf="msgType === MessageTypeEnum.CONNECTION_LOST">
        <p class="error">Verbindung verloren</p>
        <button (click) = "reconnect()">Neu verbinden</button>
    </div>
    <div *ngIf="msgType === MessageTypeEnum.ERROR">
        {{ msg }}
    </div>
    <div *ngIf="msgType === MessageTypeEnum.HOCHZEIT">
        {{ msg }} hat eine Hochzeit angekündigt
    </div>
    <div *ngIf="msgType === MessageTypeEnum.RUCKGABE">
        {{ msg }} hat das Spiel zurückgegeben
    </div>
</div>
<div class="vorbehalte" *ngIf="vorbehaltPhase && !playedVorbehalt">
    <h2>Vorbehalte</h2>
    <button class="vorbehaltButton" *ngIf="hochzeit()" (click)="vorbehalt('hochzeit')">Hochzeit</button>
    <button class="vorbehaltButton" *ngIf="ruckgabe()" (click)="vorbehalt('rückgabe')">Rückgabe</button>
    <!--<button [style.visible]="armut()" (click)="vorbehalt('armut')" disabled>Armut</button>-->
    <button class="vorbehaltButton" (click)="vorbehalt('next')">Kein Vorbehalt</button>
</div>
<div class="myCards">
    <app-card *ngFor="let cardID of myCards" [cardID]="cardID" [gameType]="gameType" (played)="onPlay($event)"></app-card>
</div>
<div class="sort">
    <input type="checkbox" [(ngModel)]="inverted" id="invert" value="Test"> Reihenfolge invertieren
</div>

<div class="shitheadPlayers" *ngIf="publicCards != null">
    <div class="shitheadPlayer" *ngFor="let player of publicCards"> 
        <h4>{{ player.name }}</h4>
        <p>Noch {{player.hand }} Handkarten</p>
        <p *ngIf="gameRunning && (player.hand === 0 && player.verdeckt === 0)">{{winners.indexOf(player.name)}}. Platz</p>
        <p><app-card *ngFor="let cardID of player.offen" [cardID]="cardID" [gameType]="gameType" (played)="onPlay($event)"></app-card></p>
        <p><app-card *ngFor="let cardID of [].constructor(player.verdeckt)" (played)="playVerdeckt()"></app-card></p>
    </div>
</div>
<div id="modal" *ngIf="!joined || schweinchen">
    <div class="modalBox" *ngIf="!joined">
        <label for="playerName">Nutzername:</label>
        <input class="textField" [(ngModel)]="playerName" id="playerName">
        <button (click) = "join()">Spiel betreten</button>
        <p class="error"> {{ loginError }}</p>
        <p>Mit dem Betreten des Spiel erklärst du dich mit dem setzen eines Session-Cookies einverstanden.</p>
        <p>Teile den Link zu dieser Seite um weitere Spieler einzuladen.</p>
        <input type="checkbox" [(ngModel)]="remember" id="remember">
        <label for="playerName">Nutzernamen merken (setzt persisteneten Cookie)</label>
    </div>
    <div class="modalBox" *ngIf="schweinchen">
        <p>Welcher Fuchs ist das Schweinchen?</p>
        <button (click) = "playSchweinchen(28)">Dieser</button>
        <button (click) = "playSchweinchen(29)">Nächster</button>
    </div>
</div>
<app-eval [eval]="eval" [player]="playerList" [untilNewGame]="untilNewGame" (newGame)="newGame()"></app-eval>